import { gql } from '@apollo/client'

export const CREATE_CONTACT = gql`
  mutation Mutation($contact: ContactInput!, $accountId: ID!) {
  createNewContact(contact: $contact, accountId: $accountId) {
    id
    first_name
    middle_initial
    last_name
    email
    email_2
    phone_2
    address
    city
    relationship
    phone
    state
    zip
    preferred_communication_method
    guardian
    power_of_attorney
    account { id }
    account_primary { id }
    permissions {
      authorize_deposits
      obtain_information
      request_disbursements
    }
  }
}
`

export const UPDATE_CONTACT = gql`
  mutation Mutation($contact: ContactUpdateInput!, $contactId: ID!) {
  updateContact(contact: $contact, contactId: $contactId) {
    id
    first_name
    middle_initial
    last_name
    email
    email_2
    phone_2
    address
    relationship
    city
    phone
    state
    zip
    preferred_communication_method
    guardian
    account_primary { id }
    power_of_attorney
    permissions {
      authorize_deposits
      obtain_information
      request_disbursements
    }
  }
}
`

export const CONTACT_UNIQUE = gql`
query Query($email: String!, $contactId: String) {
    contactEmailUnique(email: $email, contactId: $contactId)
  }
`
export const CONTACT_IS_INVITABLE = gql`
query Query($contactId: ID!) {
  contactIsInvitable(contactId: $contactId)
  }
`

export const INVITE_CONTACT_TO_JOIN = gql`
mutation Mutation($contactId: ID!) {
  inviteContactToJoin(contactId: $contactId)
  }
`

export const CONTACTS_FOR_ACCOUNT = gql`
query($accountId: ID!) {
  contacts(accountId: $accountId) {
    id
    first_name
    middle_initial
    last_name
    email
    email_2
    phone_2
    address
    city
    phone
    state
    zip
    relationship
    is_account_owner
    preferred_communication_method
    guardian
    last_active
    account_primary { id }
    power_of_attorney
    permissions {
      authorize_deposits
      obtain_information
      request_disbursements
    }
  }
}
`
