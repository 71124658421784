import { gql } from '@apollo/client';

export default gql`
directive @cacheControl(
  maxAge: Int
  scope: CacheControlScope
) on FIELD_DEFINITION | OBJECT | INTERFACE

scalar Date

type Query {
  _: Boolean
  users(data: UsersQuery): PaginatedUsers
  user(id: ID!): User
  getCurrentUser: User
}

type Mutation {
  _: Boolean
  updateUser(user: UserInput): User!
  createUser(user: UserCreateInput): User!
  signIn(email: String!, password: String!): SignInResponse!
  forgotPassword(email: String!): Boolean
  isResetPasswordValid(email: String!, passwordReset: String!): Boolean
  resetPassword(
    email: String!
    password: String!
    passwordReset: String!
  ): Boolean
  refreshToken(refreshToken: String!): RefreshTokenResponse!
  logout(refreshToken: String): Boolean!
}

type Subscription {
  _: Boolean
}

input UserInput {
  id: ID!
  first_name: String
  last_name: String
  phone: String
  email: String
  password: String
  role: String
  inactive: Boolean
}

input UserCreateInput {
  first_name: String!
  last_name: String!
  phone: String!
  email: String!
  password: String
  role: String
  inactive: Boolean
}

type PaginatedUsers {
  count: Int
  results: [User!]
}

input UsersQuery {
  search: String
  skip: Int
  take: Int
}

type User {
  id: ID!
  email: String
  first_name: String
  last_name: String
  inactive: Boolean
  phone: String
  role: String
}

type SignInResponse {
  refreshToken: String!
  token: String!
  user: User!
}

type RefreshTokenResponse {
  refreshToken: String!
  token: String!
}

enum CacheControlScope {
  PUBLIC
  PRIVATE
}

scalar Upload
`
