import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Layout, User, Users, Settings, LogOut
} from 'react-feather';
import type { Theme } from 'src/theme'
import {
  Box,
  makeStyles,
  List,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import ForwardArrow from '@material-ui/icons/ArrowForwardIosRounded';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    height: '101px',
    top: '0px',
    backgroundColor: theme.palette.background.brandDark,
    justifyContent: 'center',
  },
  itemLabel: {
    fontSize: '12px',
    paddingTop: '6px',
    paddingInlineStart: theme.spacing(3.75),
    color: theme.palette.common.white,
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.text.hint,
      color: theme.palette.background.brandDark,
    },
  },
  itemLabelSelected: {
    fontSize: '12px',
    paddingTop: '6px',
    paddingInlineStart: theme.spacing(3.75),
    color: theme.palette.common.white,
    fontWeight: 'bold',
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.selectBackgroundGrey,
      color: theme,
      fontWeight: 600
    },
    '&:hover': {
      background: theme.palette.background.paper,
      color: theme.palette.common.white,
    },

  },
  itemIcon: {
    minWidth: '20px',
    paddingInlineEnd: theme.spacing(1.1),
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.background.brandDark,
    },
  },
}))

const iconSize = 14

interface navListItem {
  path?: string
  label: string
  icon: any
  click?: any
}

interface IMenuProps {
  activePath: string
  setHeaderTitle: any
}

const MenuItems = (props: IMenuProps) => {
  const { logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const handleLogout = async (): Promise<void> => {
    try {
      logout()
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      })
    }
  }
  const navItems: navListItem[] = [
    { path: '/', label: 'Dashboard', icon: <Layout size={iconSize} /> },
    { path: '/accounts', label: 'Accounts', icon: <Users size={iconSize} /> },
    { path: '/employees', label: 'Users', icon: <User size={iconSize} /> },
    { path: '/settings', label: 'Settings', icon: <Settings size={iconSize} /> },
    {
      path: '/auth/login', click: handleLogout, label: 'Sign Out', icon: <LogOut size={iconSize} />
    },
  ]

  const classes = useStyles()
  const { activePath, setHeaderTitle } = props

  return (
    <List>
      {navItems.map((item, index) => {
        const selected = activeRoute(item.path, activePath)
        if (selected) setHeaderTitle(item.label)

        return (
          <MenuItem
            key={index}
            classes={{
              root: classes.itemLabel,
              selected: classes.itemLabelSelected
            }}
            selected={selected}
            button
            onClick={item.click ? item.click : () => { history.push(item.path) }}
          >
            <ListItemIcon className={classes.itemIcon}>
              <Box fontSize={20}>
                {item.icon}
              </Box>
            </ListItemIcon>
            <ListItemText primary={item.label} />
            <Box fontSize="14px" hidden={!selected}>
              <ForwardArrow fontSize="inherit" />
            </Box>
          </MenuItem>
        )
      })}
    </List>
  )
}

export default MenuItems

function activeRoute(path: any, current: string): boolean {
  if (current === path) return true
  if (current.startsWith(path, 1)) return true
  return current.startsWith(path.substring(1, path.length - 1), 1)
}
