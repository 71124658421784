import { makeStyles, Typography, Box } from '@material-ui/core'
import type { Theme } from 'src/theme'
import React from 'react'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  outline: {
    background: `-webkit-linear-gradient(-90deg,${theme.palette.common.white}, ${theme.palette.common.white} 10%,${theme.palette.common.black})`,
    borderRadius: '50%',
    display: 'flex',
  },

  inner: {
    borderRadius: '50%',
    marginLeft: '1px',
    marginTop: '1px',
    display: 'flex',
    justifyContent: 'center',
  },

  title: {
    color: theme.palette.common.white,
    lineHeight: '20%',
    alignSelf: 'center',

  },

  outline_drawer: {
    width: '36px',
    height: '36px',
    marginTop: '5px',
  },

  inner_drawer: {
    background: theme.palette.common.black,
    width: '34px',
    height: '34px',

  },

  title_drawer: {
    fontSize: '16px',
  },
  outline_top_bar: {
    width: '76px',
    height: '76px',
  },

  inner_top_bar: {
    background: theme.palette.background.brandDark,
    width: '74px',
    height: '74px',
  },

  title_top_bar: {
    fontSize: '30px',
  },
}));

interface ICSLogoProps {
  isDrawer?: boolean
}

const LogoICS = (props: ICSLogoProps) => {
  const classes = useStyles()
  const { isDrawer } = props

  const outline = clsx({
    [classes.outline]: true,
    [classes.outline_top_bar]: !isDrawer,
    [classes.outline_drawer]: isDrawer
  })

  const inside = clsx({
    [classes.inner]: true,
    [classes.inner_top_bar]: !isDrawer,
    [classes.inner_drawer]: isDrawer
  })

  const titleStyle = clsx({
    [classes.title]: true,
    [classes.title_top_bar]: !isDrawer,
    [classes.title_drawer]: isDrawer
  })

  return (
    <Box pt={2}>
      <div className={outline}>
        <div className={inside}>
          <Typography variant="h3" className={titleStyle}>
            <Typography>
              ICS
            </Typography>
          </Typography>
        </div>
      </div>
    </Box>
  );
}

export default LogoICS
