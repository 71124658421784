import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import type { Theme } from 'src/theme'
import {
  makeStyles, AppBar, Toolbar, Link
} from '@material-ui/core'
import LogoICSFull from 'src/components/LogoICSFull'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    height: '101px',
    top: '0px',
    backgroundColor: theme.palette.background.brandDark,
    justifyContent: 'center',
  },
}))

const Topbar = (props: any) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Link component={RouterLink} style={{ textDecoration: 'none' }} to="/">
          <LogoICSFull />
        </Link>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string
}

export default Topbar
