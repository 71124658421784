import cuid from 'cuid'
import {
  endOfMonth, lastDayOfMonth, addMonths, parseISO, startOfToday, startOfDay
} from 'date-fns'

export const getInitials = (name = ''): string => name
  .replace(/\s+/, ' ')
  .split(' ')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('')

type TrucateOptions = { length?: number, omission?: string }
export const truncate = (str: string, options?: TrucateOptions) => {
  const length = options?.length || 30
  const omission = options?.omission || '...'
  return str.length > length ? `${str.substring(0, length)}${omission}` : str
}

export const getFilePathForAzureUpload = (tableName: 'bill' | 'deposit' | 'account', accountId: string, fileName: string): string => `${accountId}/${tableName}s/${cuid()}-${fileName}`
export const validateEmail = (email) => {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const syntheticChangeEvent = (handleChange, targetName, targetValue) => {
  const event = new Event('input', { bubbles: true });
  handleChange({ ...event, target: { ...event.target, name: targetName, value: targetValue } })
}

export const touchAllFields = (formState) => ({
  ...formState.touched,
  ...Object.assign({}, ...Object.keys(formState.values).map((item) => ({ [item]: true })))
})

export const formatPhoneOnInput = (phoneNumber: string) => {
  const input = phoneNumber ? phoneNumber.trim().replace(/\D/g, '') : '';
  const size = input.length
  if (size > 6) { return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6)}` }
  if (size > 3) { return `(${input.slice(0, 3)}) ${input.slice(3)}` }
  if (size > 0) { return `(${input}` }
  return input
}

export const formatSSNOnInput = (ssn) => {
  const input = ssn ? ssn.replace(/\D/g, '') : '';
  const size = input.length
  if (size > 5) { return `${input.slice(0, 3)}-${input.slice(3, 5)}-${input.slice(5)}` }
  if (size > 3) { return `${input.slice(0, 3)}-${input.slice(3)}` }
  if (size > 0) { return `${input}` }
  return input
}

export const getNextDueDate = (dayOfMonth: number, start_date: string, lastTransaction?) => {
  const currentDayOfMonth = new Date().getDate()
  const currentMonthWithDueDayOfMonth = new Date().setDate(dayOfMonth)
  // this is to fix bug that when he makes a recurring a few months in advance
  if (start_date && parseISO(start_date) >= parseISO(new Date()?.toISOString())) {
    const startDateDay = parseISO(start_date)?.getDate()
    const startDayMonth = parseISO(start_date).getMonth()
    const startDayYear = parseISO(start_date).getFullYear()
    if (dayOfMonth >= startDateDay) {
      const lastDateOfMonth = lastDayOfMonth(new Date()?.setMonth(startDayMonth)).getDate()
      if (lastDateOfMonth <= dayOfMonth) return endOfMonth(new Date()?.setMonth(startDayMonth))
      return new Date(startDayYear, startDayMonth, dayOfMonth)
    }
    const lastDateOfNextMonth = lastDayOfMonth(addMonths(new Date().setMonth(startDayMonth), 1)).getDate()
    if (lastDateOfNextMonth < dayOfMonth) return lastDayOfMonth(addMonths(new Date().setMonth(startDayMonth), 1))
    return addMonths(new Date(currentMonthWithDueDayOfMonth).setMonth(startDayMonth), 1)
  }
  const lastDateOfNextMonth = lastDayOfMonth(addMonths(new Date(), 1)).getDate()
  if (dayOfMonth >= currentDayOfMonth) {
    // if was processed that day, should take due date from next month
    if ((lastTransaction?.processing_date || lastTransaction?.date) && dayOfMonth === currentDayOfMonth && startOfDay(parseISO(lastTransaction?.processing_date || lastTransaction?.date))?.toISOString() === startOfToday()?.toISOString() && parseISO(lastTransaction?.processing_date || lastTransaction?.date).getDate() && parseISO(lastTransaction?.processing_date || lastTransaction?.date).getDate() === dayOfMonth && lastTransaction?.status === 'Processed') {
      if (lastDateOfNextMonth < dayOfMonth) return lastDayOfMonth(addMonths(new Date(), 1))
      return addMonths(new Date(currentMonthWithDueDayOfMonth), 1)
    }
    const lastDateOfMonth = lastDayOfMonth(new Date()).getDate()
    if (lastDateOfMonth < dayOfMonth) return endOfMonth(new Date())
    return new Date(currentMonthWithDueDayOfMonth)
  }
  if (lastDateOfNextMonth < dayOfMonth) return lastDayOfMonth(addMonths(new Date(), 1))
  return addMonths(new Date(currentMonthWithDueDayOfMonth), 1)
}
