import { gql } from '@apollo/client'

export const BILL_TYPES = gql`
  query {
    billTypes {
      id
      category
      sub_item
      payment_method
    }
  }
`
export const BILL_TYPES_PAGINATED = gql`
query ($data: BillTypeQuery) {
  billTypesPaginated(data: $data) {
    count
    cursor {
        previous
        next
    }
    results {
      id
      category
      sub_item
      payment_method
      payment_method_options
    }
  }
}
`
export const BILL_TYPES_OPTIONS = gql`
  query {
    billTypes {
      id
      category
      sub_item
      payment_method_options
    }
  }
`

export const CREATE_BILL_TYPE = gql`
mutation ($data: BillTypeInputCreate) {
  createBillType(data:$data ){
      id
      category
      sub_item
      payment_method
      payment_method_options
  }
}
`

export const UPDATE_BILL_TYPE = gql`
mutation ($data: BillTypeInputUpdate) {
  updateBillType(data:$data ){
      id
      category
      sub_item
      payment_method
      payment_method_options
  }
}
`
