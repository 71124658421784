import { gql } from '@apollo/client';

export const AUDIT_LOGS = gql`
  query(
    $tableNames: [String!]!
    $rowId: String
    $rowIds:[String!]
    $actions: [AuditLogActions!]
    $skip: Int
    $take: Int
    $column_filters: [String!]
  ) {
    auditLogs(
      table_names: $tableNames
      row_id: $rowId
      row_ids: $rowIds
      actions: $actions
      skip: $skip
      take: $take
      column_filters: $column_filters
    ) {
      count
      results {
        id
        table_name
        row_id
        user_id
        first_name
        last_name
        contact_first_name
        contact_last_name
        old_fields
        new_fields
        action
        created_at
      }
    }
  }
`;
