import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Topbar } from './components'
import { NavBar } from './components/NavBar'

interface AuthLayoutProps extends RouteComponentProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100vh',
    minHeight: 'max-content',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  }
}))

const requiredTopBar = [
  '/auth/login',
  '/auth/logout',
  '/auth/resetpassword',
]

const selectNavi = (pathname: string, requiredTopBar: string[], main: any): any => {
  const found = requiredTopBar.find((element) => (element.trim() === pathname.trim()))
  return found ? (
    <>
      <Topbar />
      {main}
    </>
  ) : <NavBar mainChild={main} />
}

const Auth = (props: AuthLayoutProps) => {
  const { children } = props
  const classes = useStyles()
  const { pathname } = window.location
  const mainContent = <main className={classes.content}>{children}</main>
  return (
    <>
      {selectNavi(pathname, requiredTopBar, mainContent)}

    </>
  )
}

export default withRouter(Auth)
