import { gql } from '@apollo/client';

export const NOTES = gql`
query($data: NoteQuery!) {
  notes(data: $data) {
    count
    results {
      id
      note
      created_at
      updated_at
      updated_by {
        first_name
        last_name
      }
    }
    cursor {
      previous
      next
    }
  }
}
`

export const CREATE_ACCOUNT_NOTE = gql`
mutation($data: AccountNoteCreateInput!){
    createAccountNote(data: $data){
        id
        note
        created_at
        updated_at
        updated_by {
          first_name
          last_name
        }
  }
}
`

export const UPDATE_ACCOUNT_NOTE = gql`
mutation($data: AccountNoteUpdateInput!){
    updateAccountNote(data: $data){
        id
        note
        created_at
        updated_at
        updated_by {
          first_name
          last_name
        }
  }
}
`
export const DELETE_ACCOUNT_NOTE = gql`
mutation($id: ID!){
  deleteAccountNote(id: $id)
}
`
