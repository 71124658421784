import { gql } from '@apollo/client'

export const CREATE_UPLOAD_FILE_SAS_LINK = gql`
mutation {
  createUploadFileSASLink {
    containerUrl
    sasQuery
  }
}
`

export const VIEW_FILE_LINK = gql`
mutation($id: String!) {
  viewFileLink(id: $id)
}
`

export const CREATE_FILE = gql`
mutation createFile($billId: String, $depositId: String, $accountId: String, $profileAccountId: String, $file: FileInput!) {
  createFile(billId: $billId, depositId: $depositId, accountId: $accountId, profileAccountId: $profileAccountId, file: $file) {
    id
    name
    admin_only
    created_at
    created_by {
      first_name
      last_name
    }
    account{
      id
    }
  }
}
`
export const DELETE_FILE = gql`
mutation($id: String!) {
  deleteFile(id: $id)
}
`

export const RENAME_FILE = gql`
mutation($id: String!, $filename:String!) {
  renameFile(id: $id, filename: $filename){
      id
      name
      url
      type
      size
      admin_only
      created_at
      created_by {
        first_name
        last_name
      }
    }
}
`

export const FILES = gql`
query getAllFiles($data: FileQuery!) {
  files(data: $data) {
    count
    results {
      id
      name
      url
      admin_only
      type
      size
      created_at
      created_by {
        first_name
        last_name
      }
      deposit_files {
        file {
          id
          name
          admin_only
        }
      }
    }
    cursor {
      previous
      next
    }
  }
}
`

export const CHANGE_FILE_PERMISSION = gql`
  mutation($id: String!, $adminOnly: Boolean!) {
    changeFilePermission(id: $id, adminOnly: $adminOnly) {
      id
      name
      admin_only
    }
  }
`
