/* eslint-disable react/prop-types */
import React, {
  createContext,
  useEffect,
  useState
} from 'react'
import type { FC, ReactNode } from 'react'
import jwtDecode from 'jwt-decode'
import SplashScreen from 'src/components/SplashScreen'
import { useApolloClient, useMutation } from '@apollo/client'
import { setTokens } from 'src/utils/auth'
import {
  QUERY_GET_CURRENT_USER,
  SIGN_IN
} from 'src/apollo/queries'
import type { User } from 'src/types/user'

interface AuthState {
  isInitialised: boolean,
  isAuthenticated: boolean,
  isAdmin: boolean,
  user: User | null
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<any>,
  logout: () => void,
  register: (email: string, name: string, password: string) => Promise<void>
}

interface AuthProviderProps {
  children: ReactNode
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isAdmin: false,
  user: null
}

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false
  }

  const decoded: any = jwtDecode(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve()
})

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const client = useApolloClient()
  const [signIn] = useMutation(SIGN_IN)
  const [state, setState] = useState<AuthState>({
    isInitialised: false,
    isAuthenticated: false,
    isAdmin: false,
    user: null
  })

  const login = async (email: string, password: string) => {
    try {
      const res = await signIn({
        variables: {
          email,
          password
        },
        fetchPolicy: 'no-cache'
      })
      setTokens(res.data.signIn.token, res.data.signIn.refreshToken)

      const auth = {
        ...state,
        isInitialised: true,
        isAuthenticated: true,
        isAdmin: res.data?.signIn.user.role === 'admin',
        user: res.data.signIn.user
      }

      setState({
        ...state,
        isInitialised: true,
        isAuthenticated: true,
        isAdmin: res.data?.signIn.user.role === 'admin',
        user: res.data.signIn.user
      })

      return auth
    } catch (e) {
      console.log('Invalid username/password')
      return false
    }
  }

  const logout = () => {
    setTokens('', '')
    setState({
      ...state,
      isInitialised: true,
      isAuthenticated: false,
      isAdmin: false,
      user: null
    })
  }
  const register = async (_email: string, _name: string, _password: string) => { }

  useEffect(() => {
    const initialise = async () => {
      const accessToken = window.localStorage.getItem('token')

      if (accessToken && isValidToken(accessToken)) {
        client
          .query({
            query: QUERY_GET_CURRENT_USER,
            fetchPolicy: 'no-cache'
          })
          .then((t) => {
            // Token most likely valid but not for the server
            if (!t.data.getCurrentUser) {
              setState({
                ...state,
                isInitialised: true,
                isAuthenticated: false
              })
            } else {
              setState({
                ...state,
                isInitialised: true,
                isAuthenticated: true,
                isAdmin: t.data?.getCurrentUser.role === 'admin',
                user: t.data.getCurrentUser
              })
            }
          })
          .catch((c) => {
            console.log('getCurrentUser', c)
            setState({
              ...state,
              isInitialised: true,
              isAuthenticated: false
            })
          })
      } else {
        setState({
          ...state,
          isInitialised: true,
          isAdmin: false,
          isAuthenticated: false
        })
      }
    }

    initialise()
  }, [])

  if (!state.isInitialised) {
    return <SplashScreen />
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
