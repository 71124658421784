import React from 'react'
import type { Theme } from 'src/theme'
import {
  makeStyles,
  Typography,
  Avatar,
} from '@material-ui/core'
import { getInitials } from 'src/utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    height: '101px',
    top: '0px',
    backgroundColor: theme.palette.background.brandDark,
    justifyContent: 'center',
  },
  itemLabel: {
    fontSize: '12px',
    paddingTop: '6px',
    paddingInlineStart: theme.spacing(3.75),
    color: theme.palette.common.white,
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.text.hint,
      color: theme.palette.background.brandDark,
    },
  },
  itemLabelSelected: {
    fontSize: '12px',
    paddingTop: '6px',
    paddingInlineStart: theme.spacing(3.75),
    color: theme.palette.common.white,
    fontWeight: 'bold',
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.selectBackgroundGrey,
      color: theme,
      fontWeight: 600
    },
    '&:hover': {
      background: theme.palette.background.paper,
      color: theme.palette.common.white,
    },

  },
  itemIcon: {
    minWidth: '20px',
    paddingInlineEnd: theme.spacing(1.1),
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.background.brandDark,
    },
  },
  link: {
    textDecoration: 'none'
  },
  tool_bar: {
    justifyContent: 'flex-end',
    alignSelf: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    minWidth: '200px',
  },
  title: {
    color: theme.palette.text.primary,
    lineHeight: '20%',
    alignSelf: 'center',
    fontSize: '16px',
    fontweight: 'lighter',
    marginInline: theme.spacing(1.75),
  },
  avatar_letter: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '16px',
    width: '36px',
    height: '36px',
  }
}))

interface IUserSectionProps {
  name?: string
  avatarSrc?: string
}

const UserSection = (props: IUserSectionProps) => {
  const classes = useStyles()
  const { name } = props
  const { avatarSrc } = props
  const avatar = avatarSrc
    ? <Avatar src={avatarSrc} />
    : <Avatar className={classes.avatar_letter}>{getInitials(name)}</Avatar>

  return (
    <span className={classes.tool_bar}>
      <Typography className={classes.title}>
        {name}
      </Typography>
      {avatar}
    </span>
  )
}

export default UserSection
