import React from 'react'
import {
  makeStyles,
} from '@material-ui/core'
import NotificationBell from 'src/components/NotificationBell';
import UserSection from './UserSection';
import Search from './Search';

const useStyles = makeStyles(() => ({
  root: {
    justifySelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },

}))

interface IPropActionsRow {
  userFullName: string
  userAvatarSrc?: string
  showNotificationBubble?: boolean

}

const ActionsRow = (props: IPropActionsRow) => {
  const { userFullName, userAvatarSrc, showNotificationBubble } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Search />
      <NotificationBell showBubble={showNotificationBubble} />
      <UserSection name={userFullName} avatarSrc={userAvatarSrc} />
    </div>
  )
}

export default ActionsRow
