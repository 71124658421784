import { gql } from '@apollo/client'

export const PAYORS = gql`
  query($data: PayorsQuery) {
    payors(data: $data)
    {
        count
        results{
            id
            name
            address
            address_line_2
            city
            state
            payee_account_number
            zip
            phone
            email
            bill_type_id
            _count {
              bills
            }
            bill_type{
              id
              category
              sub_item
              payment_method_options
            }
            payment {
              id
              account_name
              account_number
              account_routing
              account_type
              quick_pay_via
              quick_pay_info
              check_number
              check_name
              account_address
              account_phone
              account_bank
              is_stored_method
              payment_method
              transaction_id
            }
            created_by{
              id
            }
        }
        cursor {
            previous
            next
        }
    }
  }
`

export const CREATE_PAYOR = gql`
 mutation ($data: CreatePayorInput!) {
  createPayor(payor: $data){
    name
    address
    address_line_2
    city
    state
    zip
    phone
    email
    payment {
      account_name
      account_number
      account_routing
      account_type
      quick_pay_via
      quick_pay_info
      check_number
      check_name
      account_address
      account_phone
      account_bank
      is_stored_method
      payment_method
    }
  }
}
`
export const DELETE_PAYOR = gql`
mutation ($id: ID!) {
  deletePayor(id: $id){
    id
  }
}
`

export const UPDATE_PAYOR = gql`
 mutation ($data: UpdatePayorInput!) {
  updatePayor(data: $data){
    name
    address
    address_line_2
    city
    state
    zip
    phone
    email
    payment {
      account_name
      account_number
      account_routing
      account_type
      quick_pay_via
      quick_pay_info
      check_number
      check_name
      account_address
      account_phone
      account_bank
      is_stored_method
      payment_method
    }
  }
}
`
