import { gql } from '@apollo/client'

export const SIGN_IN = gql`
  mutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      refreshToken
      user {
        id
        email
        role
        first_name
        last_name
      }
    }
  }
`

export const LOGOUT = gql`
  mutation($refreshToken: String!) {
    logout(refreshToken: $refreshToken)
  }
`

export const FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    forgotPassword(email: $email)
  }
`

export const VALIDATE_RESET = gql`
  mutation($email: String!, $passwordReset: String!) {
    isResetPasswordValid(email: $email, passwordReset: $passwordReset)
  }
`

export const RESET_PASSWORD = gql`
  mutation($email: String!, $passwordReset: String!, $password: String!) {
    resetPassword(email: $email, password: $password, passwordReset: $passwordReset)
  }
`

export const QUERY_GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      role
      email
      first_name
      last_name
    }
  }
`
