import { gql } from '@apollo/client'

export const STORED_PAYMENTS = gql`
query($accountId: String!, $inactive: Boolean){
    storedPayments(accountId: $accountId, inactive: $inactive){
        id
        payment_method
        account_name
        account_number
        account_bank
        account_routing
        account_type
        quick_pay_via
        quick_pay_info
        check_number
        check_name
        account_address
        account_phone
        transaction_id
        is_stored_method
        inactive
    }
}
`

export const CREATE_STORED_PAYMENT = gql`
mutation($accountId: String!, $payment: StoredPaymentInput!){
    createStoredPayment(accountId: $accountId, payment: $payment){
        id
        payment_method
        account_name
        account_number
        account_bank
        account_routing
        account_type
        quick_pay_via
        quick_pay_info
        check_number
        check_name
        account_address
        account_phone
        transaction_id
        inactive
        account {
            id
        }
    }
}
`

export const UPDATE_STORED_PAYMENT = gql`
mutation($paymentId: String!, $payment: StoredPaymentInput!){
    updateStoredPayment(paymentId: $paymentId, payment: $payment){
        id
        payment_method
        account_name
        account_number
        account_bank
        account_routing
        account_type
        quick_pay_via
        quick_pay_info
        check_number
        check_name
        inactive
        account_address
        account_phone
        transaction_id
    }
}
`
export const PAYMENT_METHOD_OPTIONS = gql`
query{
    paymentMethodOptions
}
`
