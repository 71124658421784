import { gql } from '@apollo/client'

export const UPDATE_RECURRING = gql`
 mutation ($data: RecurringUpdateInput!) {
  updateRecurring(recurring: $data){
    id
    start_date
    end_date
    confirmed
    transaction_data{
        amount
        payment_id
        payor_id
    }
    type
  }
}
`
export const RECURRING = gql`
query getRecurrings($data: RecurringsQuery!){
  recurrings(data: $data){
    count
    cursor{
      next
      previous
    }
    results {
    id
    confirmed
    disabled_reason
    start_date
    end_date
    account {
      account_number
      id
    }
    deposits {
      payment {
        payment_method
      }
      description
      deposit_type
      date
      status
      requested_by {
        first_name
        last_name
      }
    }
    bills {
      date
      processing_date
      status
      description
      bill_type {
        category
        sub_item
      }
      payment {
        payment_method
        quick_pay_via
        check_name
        account_name
      }
       payor {
        name
        address
        city
        state
        zip
        email
        phone
       }
    }
    transaction_data {
        amount
        due_date_day_number
        description
        deposit_type
        recurring_type
      }
    status
    type
    }
  }
}
`
