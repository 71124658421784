import { gql } from '@apollo/client'

export const USERS = gql`
  query($data: UsersQuery) {
    users(data: $data) {
      count
      results {
          id
          email
          role
          inactive
          first_name
          last_name
          phone
      }
    }
  }
`
export const USER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      email
      role
      inactive
      first_name
      last_name
      phone
    }
  }
`

export const UPDATE_USER = gql`
  mutation($employee: UserInput) {
    updateUser(user: $employee) {
      id
      email
      role
      inactive
      first_name
      last_name
      phone
    }
  }
`
export const CREATE_USER = gql`
  mutation($employee: UserCreateInput) {
    createUser(user: $employee) {
      id
    }
  }
`
