import {
  ApolloClient, InMemoryCache, createHttpLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getToken } from 'src/utils/auth'
// import { WebSocketLink } from '@apollo/client/link/ws'
// import { getMainDefinition } from '@apollo/client/utilities'
import typeDefs from './typeDefs'

const uri = process.env.NODE_ENV !== 'production' ? 'http://localhost:5000/graphql' : '/graphql'
// const subscriptionUri = process.env.NODE_ENV !== 'production' ? 'ws://localhost:5000/graphql' : `${window.location.origin}/graphql`.replace('https://', 'wss://') // TODO: fix this in prod!!!
const httpLink = createHttpLink({
  uri
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  return {
    'x-token': token,
    headers: {
      ...headers,
      'x-token': token || ''
    }
  }
})

// const wsLink = new WebSocketLink({
//   uri: subscriptionUri,
//   options: {
//     reconnect: true,
//     connectionParams: () => ({
//       'x-token': getToken() || ''
//     })
//   }
// })

export const createClient = () => {
  const cache = new InMemoryCache()
  // const splitLink = ({ query }: any) => {
  //   const definition = getMainDefinition(query)
  //   return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  // }

  return new ApolloClient({
    cache,
    link: authLink.concat(httpLink), // .split(httpLink),
    connectToDevTools: process.env.NODE_ENV !== 'production',
    typeDefs
  })
}
