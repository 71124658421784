import React from 'react'
import type { FC, ReactNode } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from '../hooks/useAuth'

interface AdminGuardProps {
  children?: ReactNode
}

const AdminGuard: FC<AdminGuardProps> = ({ children }) => {
  const auth = useAuth()
  const { isAdmin } = auth
  if (!isAdmin) {
    return <Redirect to="/" />
  }

  return <>{children}</>
}

AdminGuard.propTypes = {
  children: PropTypes.node
}

export default AdminGuard
