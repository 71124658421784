import { gql } from '@apollo/client'

export const BILL = gql`
  query($id: ID!) {
    bill(id: $id) {
          id
          amount
          account{
              id
              account_number
              first_name
              last_name
            }
          date
          due_date
          status
          payment_type
          payee_account_number
          description
          reason
          address
          city
          state
          zip
          email
          phone
          bill_files{
            file {
              id
              name
              admin_only
            }
          }
         bill_notes{
          id
          note
          updated_at
          updated_by {
            first_name
            last_name
          }
          }
          payment {
            id
            account_name
            account_routing
            account_number
            account_bank
            check_number
            account_type
            check_name
            account_address
            account_phone
            payment_method
            quick_pay_via
            quick_pay_info
            transaction_id
            is_stored_method
          }
          payor {
            id
            name
            address
            state
            zip
            phone
            email
            created_by {
              id
            }
            bill_type_id
            payment {
              id
              account_name
              account_number
              account_routing
              account_type
              quick_pay_via
              quick_pay_info
              check_number
              check_name
              account_address
              account_phone
              account_bank
              is_stored_method
              payment_method
              transaction_id
            }
        }
        recurring {
          id
          status
          start_date
          end_date
          transaction_data {
            recurring_type
            due_date_day_number
            bill_type_id
          }
        }
        processing_date
        processed_completed_date
        bill_type{
          id
          category
          payment_method_options
          sub_item
        }
      created_at
      created_by{
        first_name
        last_name
      }
      contact_created_by{
        first_name
        last_name
      }
    }
  }
`
export const BILLS = gql`
  query getBills($data: BillsQuery) {
    bills(data: $data)
    {
        count
        cursor {
            previous
            next
        }
        results {
          id
          amount
          description
          updated_at
          account{
              id
              account_number
              first_name
              last_name
          }
          recurring {
          status
          id
          transaction_data {
            processing_date_day_number
          }
          disabled_reason
        }
          bill_files {
            file {
              id
              name
            }
          }
          bill_notes {
          id
          note
          updated_at
          updated_by {
            first_name
            last_name
          }
          }
          date
          due_date
          status
          payment_type
          payee_account_number
          description
          address
          city
          state
          zip
          email
          phone
          payment {
            id
            account_name
            account_routing
            account_number
            account_bank
            check_number
            account_type
            check_name
            account_address
            account_phone
            payment_method
            quick_pay_via
            quick_pay_info
            transaction_id
          }
          payor {
            id
            name
            address
            city
            state
            zip
            phone
            email
            payment {
              id
              account_name
              account_number
              account_routing
              account_type
              quick_pay_via
              quick_pay_info
              check_number
              check_name
              account_address
              account_phone
              account_bank
              is_stored_method
              payment_method
              transaction_id
            }
        }
        processing_date
        processed_completed_date
    }
  }
  }
`

export const CREATE_BILL = gql`
  mutation($data: BillCreateInput!) {
      createBill(bill: $data) {
        id
      }
  }
`

export const UPDATE_BILL = gql`
mutation ($data: BillUpdateInput!, $emailText: String, $contactId: ID) {
      updateBill(bill: $data, emailText: $emailText, contactId: $contactId) {
          id
          amount
          created_at
          processing_date
          created_by{
            first_name
            last_name
          }
          contact_created_by{
            first_name
            last_name
          }
          bill_files {
            file {
              id
              name
            }
          }
          bill_notes {
          id
          note
          updated_at
          updated_by {
            first_name
            last_name
          }
          }
          date
          due_date
          status
          reason
          payment_type
          payee_account_number
          description
          address
          city
          state
          zip
          email
          phone
          payment {
            id
            account_name
            account_routing
            account_number
            account_bank
            check_number
            account_type
            check_name
            account_address
            account_phone
            payment_method
            quick_pay_via
            quick_pay_info
            transaction_id
          }
          payor {
            id
            name
            address
            city
            state
            zip
            phone
            email
            payment {
              id
              account_name
              account_number
              account_routing
              account_type
              quick_pay_via
              quick_pay_info
              check_number
              check_name
              account_address
              account_phone
              account_bank
              is_stored_method
              payment_method
              transaction_id
            }
          }
          processed_completed_date
          bill_type{
            id
            payment_method_options
            category
            sub_item
          }
      }
  }
`

export const TOTAL_BILLS = gql`
query ($status: BillStatus, $startDate: Date, $endDate: Date){
  totalBills(status: $status, startDate: $startDate, endDate: $endDate)
}
`
export const DELETE_BILL_NOTE = gql`
mutation($id: ID!){
  deleteBillNote(id: $id)
}
`

export const CREATE_BILL_NOTE = gql`
mutation($data: BillNoteCreateInput!){
  createBillNote(billNote: $data){
    id
    note
    updated_at
    updated_by {
      first_name
      last_name
    }
  }
}
`
export const UPDATE_BILL_NOTE = gql`
mutation($data: BillNoteUpdateInput!){
  updateBillNote(billNote: $data){
  id
  note
  updated_at
  updated_by {
    first_name
    last_name
  }
  }
}
`

export const DELETE_BILL = gql`
mutation($id: ID!){
  deleteBill(id: $id){
    id
  }
}
`
