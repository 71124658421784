import React from 'react'
import clsx from 'clsx'
import {
  makeStyles, Badge, IconButton
} from '@material-ui/core'
import { Bell } from 'react-feather'

const useStyles = makeStyles(() => ({
  root: {
    alignSelf: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '20px',
    height: '20px',
  },
}))

type IPropsNotification = {
  className?: any,
  showBubble?: boolean
}

const NotificationBell = (props: IPropsNotification) => {
  const {
    className, showBubble, ...rest
  } = props
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <IconButton>
        <Badge
          color="secondary"
          badgeContent=" "
          variant="dot"
          invisible={!showBubble}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Bell />
        </Badge>
      </IconButton>
    </div>
  )
}

export default NotificationBell
