import React, {
  Suspense,
  Fragment,
  lazy
} from 'react'
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom'
import AuthLayout from 'src/layouts/AuthLayout'
import LoadingScreen from 'src/components/LoadingScreen'
import AuthGuard from 'src/components/AuthGuard'
import GuestGuard from 'src/components/GuestGuard'
import AdminGuard from 'src/components/AdminGuard'

type Routes = {
  exact?: boolean,
  path?: string | string[],
  guard?: any,
  layout?: any,
  component?: any,
  routes?: Routes,
  search?: any,
  redirect?: any,
}[]

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/error/NotFoundView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/pdfStatement/:accountId/:startDate/:endDate/vod',
    component: lazy(() => import('src/views/AccountDetails/components/Statements/PDF/Vod'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/pdfStatement/:accountId/:startDate/:endDate/accountStatement',
    component: lazy(() => import('src/views/AccountDetails/components/Statements/PDF/Statement'))
  },
  {
    path: '/auth',
    guard: GuestGuard,
    layout: AuthLayout,
    routes: [{
      exact: true,
      path: '/auth/login',
      component: lazy(() => import('src/views/Login'))
    },
    {
      exact: true,
      path: '/auth/resetpassword',
      component: lazy(() => import('src/views/ResetPassword'))
    }]
  },
  {
    path: '/admin',
    guard: AdminGuard,
    layout: AuthLayout,
    routes: []
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: AuthLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/dashboard" />

      },
      {
        exact: true,
        path: '/accounts',
        component: lazy(() => import('src/views/AccountList'))
      },
      {
        exact: true,
        path: '/account/add',
        component: lazy(() => import('src/views/AccountAdmin'))
      },
      {
        exact: true,
        path: '/employees',
        component: lazy(() => import('src/views/EmployeeList'))
      },
      {
        exact: true,
        path: '/employee/:id/:tab?',
        component: lazy(() => import('src/views/EmployeeProfile'))
      },
      {
        exact: true,
        path: '/account/:id/deposits/new',
        search: '?currentUrl',
        component: lazy(() => import('src/components/NewDepositModal'))
      },
      {
        exact: true,
        path: '/account/:id/deposits/:depositId',
        search: '?currentUrl',
        component: lazy(() => import('src/views/AccountDetails/components/Deposits/components/DepositForm'))
      },
      {
        exact: true,
        path: '/account/:id/debits/:debitId',
        search: '?currentUrl',
        component: lazy(() => import('src/views/AccountDetails/components/Debits/components/DebitForm'))
      },
      {
        exact: true,
        path: '/account/:id/credits/:creditId',
        search: '?currentUrl',
        component: lazy(() => import('src/views/AccountDetails/components/Credits/components/CreditForm'))
      },
      {
        exact: true,
        path: '/account/:id/bills/new',
        search: '?currentUrl',
        component: lazy(() => import('src/views/AccountDetails/components/Bills/NewBillForm'))
      },
      {
        exact: true,
        path: '/account/:id/bills/:billId',
        search: '?currentUrl',
        component: lazy(() => import('src/views/AccountDetails/components/Bills/BillsEditForm'))
      },
      {
        exact: true,
        path: '/account/:id/fees/:feeId',
        search: '?currentUrl',
        component: lazy(() => import('src/views/AccountDetails/components/Fees/FeeForm'))
      },
      {
        exact: true,
        path: '/account/:id/:tab?',
        component: lazy(() => import('src/views/AccountDetails'))
      },
      {
        exact: true,
        path: '/settings/:tab?',
        component: lazy(() => import('src/views/Settings'))
      },
      // {
      //   exact: true,
      //   path: '/settings',
      //   component: lazy(() => import('src/views/Settings'))
      // },
      {
        exact: true,
        path: '/errors/error-401',
        component: lazy(() => import('src/views/error/NotFoundView'))
      },
      {
        exact: true,
        path: '/dashboard/:tab?',
        component: lazy(() => import('src/views/Dashboard'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
]

export default routes
