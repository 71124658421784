import { makeStyles, Typography } from '@material-ui/core'
import type { Theme } from 'src/theme'
import React from 'react'
import LogoICS from 'src/components/LogoICS'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  tool_bar: {
    justifyContent: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
  },

  title: {
    color: theme.palette.common.white,
    lineHeight: '20%',
    alignSelf: 'center',
  },

  title_top_bar: {
    fontSize: '20px',
    marginLeft: theme.spacing(2.5),
    marginRigt: theme.spacing(2.5),
  },

  title_drawer: {
    fontSize: '10px',
    marginInlineStart: theme.spacing(1.125),
    marginInlineEnd: theme.spacing(2.5),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(5.57),
    textAlign: 'start'
  },
}));

interface iLogoFullProps {
  isDrawer?: boolean
}

const LogoICSFull = (props: iLogoFullProps) => {
  const classes = useStyles()
  const { isDrawer } = props

  const titleStyle = clsx({
    [classes.title]: true,
    [classes.title_top_bar]: !isDrawer,
    [classes.title_drawer]: isDrawer
  })

  return (

    <span className={classes.tool_bar}>
      <LogoICS isDrawer={isDrawer} />
      <Typography variant="h3" className={titleStyle}>
        <p>ICS Trust</p>
        <p>Services</p>
      </Typography>
    </span>

  );
}

export default LogoICSFull
