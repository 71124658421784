import { mergeAll } from 'ramda'
import {
  colors, Theme as MuiTheme, responsiveFontSizes
} from '@material-ui/core'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows'
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette'
import { THEMES } from './constants'
import { softShadows, strongShadows } from './shadows'
import typography from './typography'

interface TypeBackground extends MuiTypeBackground {
  dark: string
  brandDark: string
  gradientDark: string
  selectBackgroundGrey: string
}

interface Palette extends MuiPalette {
  background: TypeBackground
}

export interface Theme extends MuiTheme {
  name: string,
  palette: Palette
}

type Direction = 'ltr' | 'rtl'

interface ThemeConfig {
  direction?: Direction,
  responsiveFontSizes?: boolean,
  theme?: string
}

interface ThemeOptions {
  name?: string,
  direction?: Direction,
  typography?: Record<string, any>,
  overrides?: Record<string, any>,
  palette?: Record<string, any>,
  shadows?: MuiShadows
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
}

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600],
        hover: '#F5F2F5',
      },
      background: {
        default: colors.grey[100],
        dark: '#292129',
        brandDark: '#292129',
        selectBackgroundGrey: '#F7F7F726',
        gradientDark: 'linear-gradient(180deg, #020102 17.71%, #281728 87.29%, #361F35 99.48%)',
        paper: colors.common.white
      },
      primary: {
        main: '#470F45',
      },
      secondary: {
        main: '#5850EC',
        contrastText: '#546E7A'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        hint: '#969696',
      },
      error: {
        main: '#F74545'
      },
      success: {
        main: '#388E3C'
      },
      divider: colors.blueGrey[600],
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.15)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#382C38',
        dark: '#292129',
        brandDark: '#292129',
        selectBackgroundGrey: '#F7F7F726',
        gradientDark: 'linear-gradient(180deg, #020102 17.71%, #281728 57.29%, #361F35 99.48%)',
        paper: '#433943',
      },
      primary: {
        main: colors.common.white,
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: colors.common.white,
        secondary: '#adb0bb',
        hint: '#c6cacf',
      },
      error: {
        main: '#F74545'
      },
      success: {
        main: '#388E3C'
      },
      common: {
        black: '#020102'
      },
      divider: colors.common.white,

    },
    shadows: strongShadows
  },
]

export const createTheme = (config: ThemeConfig = {
}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme)

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions
  }

  let theme = createMuiTheme(
    mergeAll([
      baseOptions,
      themeOptions,
      {
        direction: config.direction
      }
    ])
  )

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme as Theme
}
