import { validatePhoneNumber } from './validation'

export * from './readFile'
export * from './system'
export * from './validation'
export * from './helpers'
export * from '../apollo/apollo'
export * from './timeHelpers'
export * from './task'
export * from './payments'

export const formatCurrency = (amount: number | string) => Number(amount).toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const formatPhoneNumber = (phone: string) => {
  if (!phone) return phone
  if (validatePhoneNumber(phone) && !(/^\d+$/.test(phone))) return phone
  const cleanDigits: string = phone.replace(/[^\d]/g, '')
  if (cleanDigits.length < 8) return cleanDigits.replace(/(\w{1,3})(\w{4})/, '$1-$2')
  if (cleanDigits.length < 10) return cleanDigits.replace(/(\w{1,3})(\w{3})(\w{4})/, '$1-$2-$3')
  return cleanDigits.replace(/(\w{3})(\w{3})(\w{4})$/, ' ($1)-$2-$3').trim()
}
