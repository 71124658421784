import React, { useState } from 'react';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LogoICSFull from 'src/components/LogoICSFull';
import { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MenuItems from './MenuItems';
import ActionsRow from './ActionsRow';

const drawerWidth = 193;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    height: '100%',
  },
  drawer: {
    minHeight: '100vh',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerRoot: {
    background: theme.palette.background.gradientDark,
    height: '100%',
    minHeight: '400px',
    color: theme.palette.common.white,
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  headerBar: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },

}));

interface ISideNavProps extends RouteComponentProps {
  mainChild: any,
}

function NavBar(props: ISideNavProps) {
  const { mainChild, location } = props;
  const { pathname } = location
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth()
  const { first_name, last_name } = auth.user || {}
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [headerTitle, setHeaderTitle] = useState('Dashboard')

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className={classes.drawerRoot}>
      <div className={classes.toolbar}>
        <Box fontSize={10} justifyContent="center">
          <LogoICSFull isDrawer />
        </Box>
      </div>
      <MenuItems setHeaderTitle={setHeaderTitle} activePath={pathname} />
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar elevation={1} position="fixed" className={classes.appBar}>
        <Toolbar className={classes.headerBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h5" noWrap>
            {headerTitle}
          </Typography>
          <ActionsRow userFullName={`${first_name} ${last_name}`} />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>

      </nav>
      <main className={classes.content}>
        {mainChild}
      </main>
    </div>
  );
}

export default withRouter(NavBar);
