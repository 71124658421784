import { merge } from 'ramda'

export const resolveReq = async (promise: any) => {
  try {
    const { data } = await promise
    return {
      data
    }
  } catch (error) {
    const { data, statusText } = error.response
    const message = data.message || data
    console.log(data, statusText)
    return {
      error: merge(error, {
        message
      })
    }
  }
}

export const addUserFeedbackScript = () => {
  const inner = `(function () {
    window.Userback = window.Userback || {};
    Userback.access_token = '32969|60879|AvdBJDmDh4FiwXP1Gajl1cH6T';
    (function(d) {
        var s = d.createElement('script');s.async = true;
        s.src = 'https://static.userback.io/widget/v1.js';
        (d.head || d.body).appendChild(s);
    })(document);
  })();`

  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.id = 'userfeedback'
  script.innerHTML = inner

  document.body.appendChild(script)
}
