import { format, intervalToDuration } from 'date-fns'

export const getDigitalTime = (seconds?: boolean) => format(new Date(), `h:mm${seconds ? ':ss' : ''} a`)

// Since date-only forms are interpreted as a UTC time we add on time to make it a date-time form
export const dateStringToLocalDate = (date: string) => new Date(`${date}T00:00`)

export const getTimeElapsed = ({ datetime }: { datetime: string }) => intervalToDuration({
  start: new Date(datetime),
  end: new Date()
})

export const getDurationDifference = ({
  dateFrom,
  dateTo
}: {
  dateFrom: Date,
  dateTo: Date
}) => {
  intervalToDuration({
    start: new Date(dateFrom),
    end: new Date(dateTo)
  })
}
