export const getDisplayForStoredPayments = (sp) => {
  if (sp?.payment_method === 'ACH') {
    return `ACH ${sp?.account_name ? `: ${sp.account_name}` : ''}`
  }
  if (sp?.payment_method === 'Chase_Quick_Pay') {
    return `Zelle: ${sp?.quick_pay_via ? sp.quick_pay_via : ''}`
  }
  if (sp?.payment_method === 'Check') {
    return `Check: ${sp?.check_name ? sp.check_name : ''}`
  }
  if (sp?.payment_method === 'Cash') {
    return 'Cash'
  }
  return sp?.payment_method || ''
}
