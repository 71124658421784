import { gql } from '@apollo/client'

export const TASKS = gql`
query($data: TasksQueryInput!) {
    tasks(data: $data) {
     count
     cursor{
         previous
         next
     }
     results{
         id
         title
         description
         due_date
         completed
         account{
             id
             first_name
             last_name
         }
         assigned_to {
             id
             first_name
             last_name
         }
     }
    }
  }
`

export const COMPLETE_TASK = gql`
mutation($id: String!, $completed: Boolean!) {
    completeTask(id: $id, completed: $completed) {
         id
         title
         description
         due_date
         completed
     }
  }
`

export const CREATE_TASK = gql`
mutation($task: CreateTaskInput!) {
    createTask(task: $task) {
         id
         title
         description
         due_date
         account{
            id
        }
        assigned_to {
            id
            first_name
            last_name
        }
         completed
     }
  }
`

export const UPDATE_TASK = gql`
mutation($task: UpdateTaskInput!) {
    updateTask(task: $task) {
         id
         title
         description
         due_date
         account{
            id
        }
        assigned_to {
            id
            first_name
            last_name
        }
         completed
     }
  }
`

export const DELETE_TASK = gql`
  mutation($id: String!){
      deleteTask(id: $id){
          id
      }
  }
`
