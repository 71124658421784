import React from 'react'
import { makeStyles, IconButton } from '@material-ui/core'
import { Search as SearchIcon } from 'react-feather'

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    marginInline: theme.spacing(3)
  },
}))

const Search = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <IconButton disabled>
        <SearchIcon />
      </IconButton>
    </div>
  );
}

export default Search
