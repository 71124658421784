import { gql } from '@apollo/client'

export const CREATE_FEE = gql`
 mutation($data: CreateFeeInput!){
createFee(fee:$data){
   id
    }
   }
`

export const TOTAL_FEES = gql`
query($startDate: Date, $endDate: Date){
  totalFees(startDate: $startDate, endDate: $endDate)
}
`
export const UPDATE_FEE = gql`
mutation($data: UpdateFeeInput!){
  updateFee(fee: $data){
    id
    name
    amount
    date
  }
}
`

export const FEE = gql`
query($id: ID!) {
  fee(id: $id) {
    description
    name
    amount
    date
    id
    account{
      id
       }
    }

}`

export const FEES = gql`
  query getFees($data: FeeQuery!) {
    fees(data: $data)
    {
        count
        cursor {
            previous
            next
        }
        results {
          id
          amount
          created_by {
            first_name
            last_name
          }
          account{
              id
              account_number
              first_name
              last_name
          }
          date
          name
          description
    }
  }
  }
`

export const DELETE_FEE = gql`
mutation($id: ID!){
  deleteFee(id: $id){
    id
  }
}
`
