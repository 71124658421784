import { gql } from '@apollo/client';

export const TRANSACTIONS = gql`
  query getTransactions($data: TransactionQueryInput!) {
    transactions(data: $data) {
      count
      balance
      results {
        id
        amount
        description
        date
        status
        transaction_type
        record_type
        account_balance
        account_id
        transaction_id
      }
    }
  }
`;

export const TRANSACTIONS_FOR_STATEMENT = gql`
  query($data: StatementQuery!) {
    transactionsForStatement(data: $data) {
      id
      description
      account_balance
      amount
      status
      date
      transaction_type
      transaction_id
      record_type
    }
  }
`;

export const TRANSACTIONS_FOR_DASHBOARD = gql`
  query($data: TransactionDashboardQueryInput!) {
    transactionsForDashboard(data: $data) {
      count
      balance
      results {
        id
        amount
        description
        date
        status
        transaction_type
        record_type
      }
    }
  }
`;
