import { gql } from '@apollo/client'

export const ACCOUNT = gql`
  query($id: ID!) {
    account(id: $id) {
      id
      first_name
      middle_initial
      last_name
      spouse_name
      date_of_birth
      ssn
      is_inactive
      created_at
      status
      medicaid_cin
      account_number
      monthly_income
      medical_premium
      gender
      monthly_fee
      annual_fee
      enrollment_fee
      trust_type
      marital_status
      date_established
      date_of_death
      mltc_plan
      monthly_surplus_amount
      profile_picture {
        id
        name
        url
      }
      referrals {
      id
      name
      email
      phone
      company
      address
      occupation
      city
      zip
      state
    }
      contacts{
        id
        first_name
        middle_initial
        last_name
        email
        email_2
        phone_2
        phone
        relationship
        is_account_owner
        address
        city
        state
        zip
        preferred_communication_method
        permissions {
          authorize_deposits
          obtain_information
          request_disbursements
        }
        guardian
        power_of_attorney
      }
      primary_contact {
        id
      }
    }
  }
`

export const ACCOUNTS = gql`
  query getAllAccounts($data: AccountsQuery) {
    accounts(data: $data) {
      count
      results {
        id
        first_name
        last_name
        middle_initial
        account_number
        status
        created_at
        mltc_plan
        date_established
        primary_contact {
          id
          phone
          email
          first_name
          last_name
       }
       referrals {
        id
        name
       }
        contacts {
          id
          phone
          email
          first_name
          last_name
          relationship
          is_account_owner
        }
      }
      cursor {
        next
        previous
      }
    }
  }
`
export const ACCOUNT_BALANCE = gql`
  query getAccountBalance($data: AccountBalanceInput!) {
    accountBalance(data: $data)
  }
`
export const CREATE_ACCOUNT = gql`
 mutation ($createAccountAccount: AccountCreateInput!) {
  createAccount(account: $createAccountAccount){
    id
    first_name
    last_name
    account_number
    status
    created_at
    is_inactive
    monthly_surplus_amount
    }
  }
`
export const UPDATE_ACCOUNT = gql`
 mutation ($updateAccount: AccountUpdateInput!) {
  updateAccount(account: $updateAccount){
    id
    first_name
    middle_initial
    spouse_name
    last_name
    date_of_birth
    ssn
    is_inactive
    monthly_income
    medical_premium
    created_at
    status
    medicaid_cin
    account_number
    gender
    date_established
    date_of_death
    mltc_plan
    monthly_fee
    marital_status
    profile_picture {
      id
      name
      url
    }
    contacts{
      id
      first_name
      last_name
      email
      email_2
      phone_2
      phone
      is_account_owner
      address
      relationship
      city
      state
      zip
    }
  }
}
`

export const MLTC_PLANS = gql`
 query mltcPlans{
  mltcPlans
 }
`
