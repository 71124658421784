import { gql } from '@apollo/client'

export const SETTINGS = gql`
query($settingType: SettingType) {
    settings(settingType: $settingType) {
      id
      name
      value
      type
    }
  }
`

export const CREATE_SETTING = gql`
mutation($data: CreateSettingInput!){
  createSetting(setting: $data){
    id
  }
}
`
export const UPDATE_SETTING = gql`
mutation($data: UpdateSettingInput!){
  updateSetting(setting: $data){
    id
  name
  value
  }
}
`
