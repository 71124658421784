import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
// import { createBrowserHistory } from 'history'
import ScrollReset from 'src/components/ScrollReset'
import routes, { renderRoutes } from 'src/routes'
import { CssBaseline } from '@material-ui/core'
import { createTheme } from './theme'
import './mixins/chartjs'
import './assets/css/prism.css'
import useSettings from './hooks/useSettings'
import { addUserFeedbackScript } from './utils'
import useAuth from './hooks/useAuth';

// const history = createBrowserHistory()

const App = () => {
  const { settings } = useSettings()
  const auth = useAuth()

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      addUserFeedbackScript()
    }
  }, [auth.isAuthenticated])

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {/* <GoogleAnalytics /> <CookiesNotification /> */}
        <ScrollReset />
        <CssBaseline />
        {renderRoutes(routes)}
      </Router>
    </ThemeProvider>
  )
}

export default App
