import { gql } from '@apollo/client'

export const DEPOSIT = gql`
  query($id: ID!) {
    deposit(id: $id) {
      id
      created_at
      created_by{
        first_name
        last_name
      }
      contact_created_by{
        first_name
        last_name
      }
       account{
         id
         account_number
         first_name
         last_name
       }
       hide_on_vod
       date
       amount
       deposit_type
       description
       pay_period
       payment{
         id
         payment_method
         account_address
         account_routing
         account_type
         check_name
         check_number
         account_phone
         quick_pay_via
         account_name
         account_number
         account_phone
         account_bank
         quick_pay_info
         transaction_id
         is_stored_method
       }
       requested_by{
         id
         first_name
         last_name
       }
       status
       deposit_files{
            file {
              id
              name
              admin_only
            }
      }
      deposit_notes {
        id
        note
        updated_at
        created_at
        updated_by {
          first_name
          last_name
        }
      }
      recurring{
         id
         start_date
         end_date
         status
         transaction_data {
          amount
          description
          due_date_day_number
          deposit_type
          requested_by_id
         }
       }
    }
  }
`
export const DEPOSITS = gql`
query getDeposits($data: DepositsQuery!){
    deposits(data: $data){
       count
       cursor{
        previous
        next
       }
       results{
         id
        hide_on_vod
       account{
         id
        account_number
        first_name
        last_name
       }
       deposit_files{
            file {
              id
              name
            }
      }
      deposit_notes{
        id
        note
        updated_at
        created_at
        updated_by {
          first_name
          last_name
        }
      }
       date
       amount
       pay_period
       created_at
       description
       deposit_type
       payment{
         id
         payment_method
         account_address
         account_routing
         account_type
         check_name
         check_number
         account_phone
         quick_pay_via
         quick_pay_info
         account_name
         account_number
         account_phone
         account_bank
         is_stored_method
         transaction_id
       }
       status
       requested_by{
         id
         first_name
         last_name
       }
       recurring{
         id
         start_date
       }
     }
    }
  }
  `

export const VERIFIED_DEPOSITS = gql`
query($data: VerifiedDepositsQuery!){
    verifiedDeposits(data: $data){
      id
      date
      amount
      description
      deposit_type
      amount
      status
      payment {
        check_number
        transaction_id
      }
    }
  }
  `

export const CREATE_DEPOSIT = gql`
mutation($data: DepositCreateInput!){
    createDeposit(deposit: $data)
    {
      id
      account {
        id
      }
    }
  }
`
export const MONTHS = gql`
query { months  }
`
export const STATUSES = gql`
query($tableName: String!){
  statusList(tableName: $tableName)
}
`

export const UPDATE_DEPOSIT = gql`
mutation($data: DepositUpdateInput!){
    updateDeposit(deposit: $data)
    {
      id
      status
      amount
      deposit_type
      hide_on_vod
      pay_period
      date
      description
      created_at
      created_by{
        first_name
        last_name
      }
      contact_created_by{
        first_name
        last_name
      }
      requested_by{
        id
        first_name
        last_name
      }
      payment{
      id
      payment_method
      account_address
      account_routing
      account_type
      check_name
      check_number
      account_phone
      quick_pay_via
      quick_pay_info
      account_name
      account_number
      account_phone
      account_bank
      transaction_id
      }
      deposit_files{
            file {
              id
              name
            }
      }
      deposit_notes{
        id
        note
      }

    }
  }
`

export const TOTAL_DEPOSITS = gql`
query($status: DepositStatus, $startDate: Date, $endDate: Date, $depositTypes: [DepositType]){
  totalDeposits(status: $status, startDate: $startDate, endDate: $endDate, depositTypes: $depositTypes)
}
`
export const DELETE_DEPOSIT_NOTE = gql`
mutation($id: ID!){
  deleteDepositNote(id: $id)
}
`

export const CREATE_DEPOSIT_NOTE = gql`
mutation($data: DepositNoteCreateInput!){
  createDepositNote(depositNote: $data){
    id
    note
    updated_at
    created_at
    updated_by {
      first_name
      last_name
    }
  }
}
`
export const UPDATE_DEPOSIT_NOTE = gql`
mutation($data: DepositNoteUpdateInput!){
  updateDepositNote(depositNote: $data){
  id
  note
  updated_at
  created_at
  updated_by {
    first_name
    last_name
  }
  }
}
`

export const DELETE_DEPOSIT = gql`
mutation($id: ID!){
  deleteDeposit(id: $id){
    id
  }
}
`
